import React from 'react'

const LogoPwc = props => (
  <svg width={62.9} height={23.88} {...props}>
    <g data-name="Layer 2">
      <g opacity={0.4} fill="#283141" data-name="Layer 1">
        <path d="M.07 22.14L1.94 22l.36-.42v-18H0V2l6.07-2h1.7v2.2C10.79.46 12 0 13.51 0c3.61 0 6.36 3.41 6.36 7.9 0 5.25-3.77 8.76-9.34 8.76-.66 0-1.71-.07-2.76-.16v5.05l.43.45 2.16.17-.06 1.74H.07zm7.7-7.51a5.37 5.37 0 0 0 .89.06c3.38 0 5.18-2.1 5.18-6 0-3.35-1.48-5.12-4.2-5.12a11.89 11.89 0 0 0-1.87.17zM27.23.1a17.58 17.58 0 0 1 .43 2.79l.36 8 4.79-9v-1l4.95-.4.62 12.51 4.23-5.87-.92-6a3.35 3.35 0 0 1 2.53-1 2.48 2.48 0 0 1 2.69 2.56c0 1.6-.89 3.51-3.08 6.56-.69 1-2.73 3.47-6.07 7.54h-3.87l-.76-11.41L27 16.66h-4.16l.32-1.48-.52-11.87-3.25-.52V1.48L25.33.1zM57.13 1.77c-2.79.43-4.23 2.49-4.23 6.07s1.93 6 4.82 6c1.38 0 2.62-.43 5.18-1.67v2.92a16.07 16.07 0 0 1-7.44 1.83 8.09 8.09 0 0 1-6.2-2.29 8.23 8.23 0 0 1-2.4-6c0-5.12 3.87-8.63 9.55-8.63 3.77 0 6.36 1.71 6.36 4.16a2.73 2.73 0 0 1-3 2.73 4.82 4.82 0 0 1-2.62-.79z" />
      </g>
    </g>
  </svg>
)

export default LogoPwc

import React from 'react'

const Verbal = props => (
  <svg width={35.26} height={35.26} {...props}>
    <g data-name="Layer 2">
      <path
        fill="#fd8023"
        d="M31.73 0H3.53A3.53 3.53 0 0 0 0 3.53v31.73l7.05-7h24.68a3.54 3.54 0 0 0 3.53-3.53V3.53A3.54 3.54 0 0 0 31.73 0zM7.05 12.34h21.16v3.53H7.05zm14.11 8.82H7.05v-3.53h14.11zm7.05-10.58H7.05V7.05h21.16z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default Verbal

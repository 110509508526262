import React from 'react'

const Numerical = props => (
  <svg width={34.59} height={39.41} {...props}>
    <g data-name="Layer 2">
      <path
        fill="#fd8023"
        d="M31.17 0H3.42A3.42 3.42 0 0 0 0 3.42V36a3.42 3.42 0 0 0 3.42 3.42h27.75A3.42 3.42 0 0 0 34.59 36V3.42A3.42 3.42 0 0 0 31.17 0zM10.39 34.28H5.3V29h5.09zm0-9.29H5.3V19.7h5.09zm9.45 9.29h-5.09V29h5.09zm0-9.29h-5.09V19.7h5.09zm8.79 9.28h-5.09V19.7h5.09zm.66-19.65H5.93V5h23.36z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default Numerical

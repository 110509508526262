import React, { useEffect, useState } from 'react'
import { window } from 'browser-monads'
import { Search } from 'react-feather'
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap'
import Footer from '../components/Footer'
import FirstMenu from '../components/FirstMenu'
import topSectionBack from '../images/bg.jpg'
import questionsSectionBack from '../images/questions-bg.png'
import LogoPwc from '../components/Icons/LogoPwc'
import LogoKpmg from '../components/Icons/LogoKpmg'
import LogoEy from '../components/Icons/LogoEy'
import LogoDeloitte from '../components/Icons/LogoDeloitte'
import LogoMcKinsey from '../components/Icons/LogoMcKinsey'
import Interview from '../components/Icons/Interview'
import Psychometric from '../components/Icons/Psychometric'
import JobApplications from '../components/Icons/JobApplications'
import Numerical from '../components/Icons/Numerical'
import Verbal from '../components/Icons/Verbal'
import Diagrammatic from '../components/Icons/Diagrammatic'
import PageHelmet from '../components/PageHelmet'
import CompetencyCard from '../components/CompetencyCard'
import AptitudeCard from '../components/AptitudeCard'
import InterviewCard from '../components/InterviewCard'
import RecentlyUpdatedArticles from '../components/RecentlyUpdatedArticles'
import LatestFromWikiJob from '../components/LatestFromWikiJob'
import TrendingArticles from '../components/TrendingArticles'
import SplashCTASection from '../components/SplashCTASection'
import './layout.css'

const PageHome = ({ location }) => {
  const [search, setSearch] = useState('')

  const onPressEnterSearch = (event) => {
    const e = event.nativeEvent
    if (e.code === 'Enter' && typeof window !== 'undefined') {
      window.location.href = `/search?q=${search}`
      setSearch('')
    }
  }

  const onChangeSearchValue = (event) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'componentDidMount' })
    }
  }, [])

  return (
    <div id="home-page" className="d-flex flex-column">
      <PageHelmet
        {...{
          title: 'Interview Tips & Practice Aptitude Tests',
          description:
            'WikiJob is the best place to prepare for your interview, assessment centre or aptitude test. Read expert advice and speak to other candidates.',
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      >
        <script
          {...{
            type: 'text/javascript',
            async: 'async',
            datanoptimize: '1',
            datacfasync: 'false',
            src: 'https://scripts.mediavine.com/tags/wiki-job.js',
          }}
        />
      </PageHelmet>
      <div className="white-header">
        <FirstMenu id="header" location={location} />
      </div>
      {/* NOTE: Should be switched off for split testing in this version / hidden in style below for now */}
      <div
        className="top-section"
        style={{
          display: 'none',
          backgroundImage: `url(${topSectionBack})`,
        }}
      >
        <div id="content" className="container">
          <h1>Get The Job You Want</h1>
          <p>
            Learn how to succeed at interviews, assessment centres and
            psychometric tests.
          </p>
          <div className="top-section-links">
            <a href="/aptitude-tests-home">Practice Free Aptitude Tests</a>
            <a href="https://forum.wikijob.co.uk">View Our Forum</a>
            <a href="/content/interview-advice">Read About Interviews</a>
          </div>
          <Nav>
            <NavItem className="input-group">
              <NavLink
                href={`/search?q=${search}`}
                disabled={search.length === 0}
                className="search-button input-group-addon"
              >
                <Search size="20" color="white" />
              </NavLink>

              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                value={search}
                onChange={onChangeSearchValue}
                onKeyPress={onPressEnterSearch}
              />
            </NavItem>
          </Nav>
        </div>
      </div>

      <div className="section py-3 pb-5">
        <div className="container">
          <h2>Latest from WikiJob</h2>
          <div className="under-heading-line" />
          <LatestFromWikiJob />
        </div>
      </div>

      <SplashCTASection />

      <div className="companies-section">
        <div className="container">
          <Row>
            <Col md={2}>
              <span className="label">Get jobs at companies such as:</span>
            </Col>
            <Col md={10}>
              <span className="logos">
                <LogoPwc />
                <LogoKpmg />
                <LogoEy />
                <LogoDeloitte />
                <LogoMcKinsey />
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className="section aptitude-section section-grey py-5">
        <div className="container text-center">
          <h2 style={{ color: '#ff8000', paddingTop: '0' }}>Aptitude Tests</h2>
          <p>
            Free practice aptitude tests to prepare you for the types of
            psychometric test used in many job application processes.
          </p>

          <Row className="pt-5">
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834507-numericalreasoning.png?width=314"
                title="Numerical Reasoning"
                description="Try this numerical reasoning practice test similar to tests used by many companies as part of their application process."
                url="/aptitude-test/numerical-practice"
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834513-verbalreasoning.png?width=314"
                title="Verbal Reasoning"
                description="Verbal reasoning tests are used by interviewers to find out how well a candidate can assess verbal logic."
                url="/aptitude-test/verbal-reasoning-practice"
                limit={5}
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834497-diagrammaticreasoning.png?width=314"
                title="Diagrammatic Reasoning"
                description="Diagrammatic reasoning tests assess your capacity for logical reasoning, using flowcharts and diagrams. Try these five practice questions, designed to be similar to real tests."
                url="/aptitude-test/diagrammatic-reasoning"
                questions={5}
                limit={5}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834503-mechanicalreasoning.png?width=314"
                title="Mechanical Reasoning"
                description="Mechanical tests assess your ability to use engineering principles to identify the correct solution to a problem. They are a test of applied knowledge. Test of your applied knowledge."
                url="/aptitude-test/mechanical-reasoning"
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834501-logicalreasoning.png?width=314"
                title="Logical Reasoning"
                description="Logical reasoning tests examine your ability to interpret patterns, number sequences or relationships between shapes. They are similar to diagrammatic reasoning tests."
                url="/aptitude-test/logical-reasoning"
                limit={15}
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834509-situationaljudgement.png?width=314"
                title="Situational Judgement"
                description="Situational Judgement tests assess your ability at solving problems in work-related situations. Try these five practice questions, designed to be similar to those used by major graduate employers."
                url="/aptitude-test/situational-judgement"
                limit={15}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582828215-watsonglaser.png?width=314"
                title="Watson Glaser"
                description="A Watson Glaser test is designed to assesses your ability to digest and understand situations and information; it is frequently used by law firms. Try these 10 questions as an introduction."
                url="/aptitude-test/watson-glaser"
                questions={10}
                limit={10}
                pass={70}
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834803-inductivereasoning.png?width=314"
                title="Inductive Reasoning"
                description="Inductive reasoning tests are designed to find your ability to see patterns and consistencies in data, and to work flexibly with unfamiliar information. Try out these sample questions."
                url="/aptitude-test/inductive-reasoning"
                limit={10}
                questions={10}
                pass={70}
              />
            </Col>
            <Col md={4}>
              <AptitudeCard
                image="https://www.datocms-assets.com/7756/1582834494-abstractreasoning.png?width=314"
                title="Abstract Reasoning"
                description="Abstract tests assess general intellect, and your ability to work with new concepts without prior knowledge. They are closely correlated with IQ tests. If you're interested, try the test below."
                url="/aptitude-test/abstract-reasoning"
                questions={10}
                limit={15}
              />
            </Col>
          </Row>
          <div className="pt-4 text-center">
            <a href="/aptitude-tests-home" className="btn btn-orange">
              <strong>See All Practice Tests</strong>
            </a>
          </div>
        </div>
      </div>

      <div className="section competencies-section">
        <div className="container text-center">
          <h2>Competencies</h2>
          <p>
            Explore the main competencies employers look for when assessing
            candidates at interview.
          </p>

          <Row>
            <Col md={3}>
              <CompetencyCard
                title="Key Competencies"
                text="Find out about the most important key competencies that firms look for in candidates and how to demonstrate them effectively at interview."
                url="/content/interview-advice/competencies/key-competencies"
              />
            </Col>
            <Col md={3}>
              <CompetencyCard
                title="Core Competencies"
                text="Discover the most common core competencies that employers want to see and how to demonstrate each of them."
                url="/content/interview-advice/competencies/core-competencies"
              />
            </Col>
            <Col md={3}>
              <CompetencyCard
                title="Interpersonal Skills"
                text="What are the 10 interpersonal skills employers value most? Read definitions, get examples and learn how to demonstrate them effectively."
                url="/content/interview-advice/competencies/interpersonal-skills"
              />
            </Col>
            <Col md={3}>
              <CompetencyCard
                title="Soft Skills"
                text="Learn about the 10 key interpersonal skills you will need to demonstrate and why they are important."
                url="/content/interview-advice/competencies/soft-skills"
              />
            </Col>

            <Row className="py-5">
              <Col md={3}>
                <CompetencyCard
                  title="Communication"
                  text="Good communication skills are vital to all employers. Find out about the top 10 communication skills that employers want to see."
                  url="/content/interview-advice/competencies/communication"
                />
              </Col>
              <Col md={3}>
                <CompetencyCard
                  title="Transferable Skills"
                  text="Transferable skills are particularly vital when applying for graduate jobs. Find out how to demonstrate the 10 most important."
                  url="/content/interview-advice/competencies/transferable-skills"
                />
              </Col>
              <Col md={3}>
                <CompetencyCard
                  title="Technical Skills"
                  text="Find out the key skills you will need to demonstrate for technical graduate jobs and how to push them at interview."
                  url="/content/interview-advice/competencies/technical-skills"
                />
              </Col>
              <Col md={3}>
                <CompetencyCard
                  title="Problem-Solving"
                  text="Find out what problem-solving skills are and why it is important to graduate employers. With example questions."
                  url="/content/interview-advice/competencies/problem-solving"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </div>

      <div className="section section-grey">
        <div className="container text-center pb-5">
          <h2>Interview Questions</h2>
          <p>
            When preparing for an interview, it’s important that you familiarise
            yourself with the kinds of questions that are most likely to be
            posed, and think about how you might approach your answers. These
            articles offer detailed explanations and examples relating to the
            most used interview questions to help you prepare.
          </p>
          <Row>
            <Col md={3}>
              <InterviewCard
                title="What are your strengths?"
                description="How to answer the most common interview question"
                url="/content/interview-advice/interview-questions/what-are-your-strengths"
                image="https://www.datocms-assets.com/7756/1544631726-fitness-826940640.jpg?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="What are your weaknesses?"
                description="Learn how to discuss weaknesses effectively at interview."
                url="/content/interview-advice/interview-questions/what-are-your-weaknesses"
                image="https://www.datocms-assets.com/7756/1544700923-what-weakness.jpg?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="What is your greatest accomplishment?"
                description="5 tips on how to prepare well for this challenging behavioural question."
                url="/content/interview-advice/interview-questions/what-is-your-greatest-accomplishment"
                image="https://www.datocms-assets.com/7756/1550767343-pexels-photo-372098.jpeg?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="Questions to ask at interview"
                description="You'll be expected to ask questions at the end of your interview."
                url="/content/interview-advice/interview-questions/questions-ask-your-interviewer"
                image="https://wikijob752372659.files.wordpress.com/2018/03/agreement-2679506_640.jpg?width=228"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <InterviewCard
                title="Why are you applying for this position?"
                description="How to build & phrase the perfect response."
                url="/content/interview-advice/interview-questions/how-answer-question-why-are-you-applying-position"
                image="https://www.datocms-assets.com/7756/1544700855-why-applying-position.jpg?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="What makes you unique?"
                description="Come across as interesting and valuable, with model answers."
                url="/content/interview-advice/interview-questions/what-makes-you-unique"
                image="https://www.datocms-assets.com/7756/1549468787-work-meeting-outside.jpg?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="What interests you about your job?"
                description="Key tips on how to give an answer that will impress."
                url="/content/interview-advice/interview-questions/what-interests-you-about-job"
                image="https://s3.amazonaws.com/wj-cdn/uploads/f4c871865b91264b52ad9112c438d14c?width=228"
              />
            </Col>
            <Col md={3}>
              <InterviewCard
                title="Where do you see yourself in 5 years?"
                description="Learn what interviewers are really asking."
                url="/content/interview-advice/interview-questions/where-do-you-see-yourself-5-years"
                image="https://www.datocms-assets.com/7756/1544709825-pexels-photo-269798.jpeg?width=228"
              />
            </Col>
          </Row>
          <div className="pt-5 text-center">
            <a
              href="/content/interview-advice/interview-questions"
              className="btn btn-orange"
            >
              <strong>See More</strong>
            </a>
          </div>
        </div>
      </div>

      {/* NOTE: Should be switched off for split testing in this version / hidden in style below for now */}
      <div className="section py-4 pb-5" style={{ display: 'none' }}>
        <div className="container">
          <RecentlyUpdatedArticles />
        </div>
      </div>

      <div className="section section-grey py-4 pb-5">
        <div className="container">
          <TrendingArticles />
        </div>
      </div>

      <div className="section tips-section d-none">
        <div className="container">
          <Row className="align-items-center">
            <Col md={7}>
              <h3 style={{ color: '#535964' }}>Need Tips?</h3>
              <h3 style={{ color: '#ed863e' }}>Read Expert Advice.</h3>
              <p
                style={{
                  color: '#2a3140',
                  maxWidth: '400px',
                  margin: '20px 0',
                }}
              >
                Learn everything you could want to know about interviews,
                psychometric tests and job applications – written by industry
                professionals.
              </p>
            </Col>
            <Col md={5}>
              <a href="/content/interview-advice" className="link-with-icon">
                <span style={{ minWidth: '50px' }}>
                  <Interview />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Read About Interviews
                  <div style={{ color: '#a5a8ad', fontSize: '13px' }}>
                    Click to Read
                  </div>
                </span>
              </a>
              <a
                href="https://www.wikijob.co.uk/content/aptitude-tests/test-types/what-psychometric-test"
                className="link-with-icon"
              >
                <span style={{ minWidth: '50px' }}>
                  <Psychometric />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Read About Psychometric Tests
                  <div style={{ color: '#a5a8ad', fontSize: '13px' }}>
                    Click to Read
                  </div>
                </span>
              </a>
              <a href="/content/application-advice" className="link-with-icon">
                <span style={{ minWidth: '50px' }}>
                  <JobApplications />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Read About Job Applications
                  <div style={{ color: '#a5a8ad', fontSize: '13px' }}>
                    Click to Read
                  </div>
                </span>
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section application-section">
        <div className="container">
          <Row className="align-items-center">
            <Col md={6}>
              <a
                href="https://www.wikijob.co.uk/aptitude-test/numerical-practice"
                className="link-with-icon"
              >
                <span style={{ minWidth: '50px' }}>
                  <Numerical />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Numerical Reasoning
                  <p style={{ fontWeight: '300', maxWidth: '300px' }}>
                    Numerical reasoning tests assess how you understand
                    numerical information, then apply operations to solve them
                  </p>
                </span>
              </a>
              <a
                href="https://www.wikijob.co.uk/aptitude-test/verbal-reasoning-practice"
                className="link-with-icon"
              >
                <span style={{ minWidth: '50px' }}>
                  <Verbal />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Verbal Reasoning
                  <p style={{ fontWeight: '300', maxWidth: '300px' }}>
                    Verbal reasoning tests are used by interviewers to find out
                    how well a candidate can assess verbal logic.
                  </p>
                </span>
              </a>
              <a
                href="https://www.wikijob.co.uk/aptitude-test/diagrammatic-reasoning"
                className="link-with-icon"
              >
                <span style={{ minWidth: '50px' }}>
                  <Diagrammatic />
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#535964',
                    marginLeft: '10px',
                  }}
                >
                  Diagrammatic Reasoning
                  <p style={{ fontWeight: '300', maxWidth: '300px' }}>
                    Diagrammatic reasoning tests assess your capacity for
                    logical reasoning, using flowcharts and diagrams.
                  </p>
                </span>
              </a>
            </Col>
            <Col md={6}>
              <h3 style={{ color: '#535964' }}>
                Preparing for an Application?
              </h3>
              <h3 style={{ color: '#ed863e' }}>Take These Practice Tests.</h3>
              <p
                style={{
                  color: '#2a3140',
                  margin: '20px 0',
                }}
              >
                Try our free aptitude tests for numerical, verbal, diagrammatic,
                mechanical, logical, inductive and abstract reasoning, plus
                situational judgement and Watson Glaser tests.
              </p>

              <a href="/aptitude-tests-home" className="homepage-section-link">
                Take Free Aptitude Tests
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="section questions-section"
        style={{ backgroundImage: `url(${questionsSectionBack})` }}
      >
        <div className="container">
          <Row>
            <Col md={6}>
              <h3 style={{ color: '#535964' }}>Have Questions?</h3>
              <h3 style={{ color: '#ed863e' }}>Get Instant answers.</h3>
              <p
                style={{
                  color: '#2a3140',
                  maxWidth: '400px',
                  margin: '20px 0',
                }}
              >
                Talk to others in our forum: ask about the application process
                at a particular company, what to expect in an interview, or what
                tests an organisation uses.
              </p>

              <a
                href="https://forum.wikijob.co.uk"
                className="homepage-section-link"
              >
                View Our Forum
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <Footer mainPage />
    </div>
  )
}

export default PageHome

import React from 'react'
import { Link } from 'gatsby'
import './competencycard.css'

export default function CompetencyCard(props) {
  return (
    <div className='text-center'>
      <h3 style={{ fontSize: '1.3em' }}>{props.title}</h3>
      <p className='competency-height'>{props.text}</p>
      <Link className='btn btn-orange' to={props.url}>
        Learn More
      </Link>
    </div>
  )
}

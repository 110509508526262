import React from 'react'

const LogoEy = props => (
  <svg width={60.33} height={50.35} {...props}>
    <g data-name="Layer 2">
      <g opacity={0.4} fill="#283141" data-name="Layer 1">
        <path d="M0 22L60.33 0v11.37zM6.9 41.75h7.9v-4.57H6.9v-3.61h8.74l-2.89-5H.39v21.78H17.8v-5H6.9zm22.51-13.19l-3.68 7.12L22 28.56h-7.2l7.6 13.19v8.6h6.52v-8.6l7.62-13.19z" />
      </g>
    </g>
  </svg>
)

export default LogoEy

import React from 'react'

const Psychometric = props => (
  <svg width={44.02} height={39.41} {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1" fill="#fd8023">
        <path d="M23.43 15.34l-2.59-2a9.25 9.25 0 0 0 .09-1.19 6.6 6.6 0 0 0-.09-1.15l2.57-2a.63.63 0 0 0 .15-.77L21.13 4a.61.61 0 0 0-.75-.27l-3 1.21a9.05 9.05 0 0 0-2.08-1.23l-.46-3.2a.58.58 0 0 0-.58-.51H9.4a.62.62 0 0 0-.6.51l-.46 3.2A10.11 10.11 0 0 0 6.27 4.9l-3-1.21a.63.63 0 0 0-.76.31L.08 8.16a.59.59 0 0 0 .15.77L2.8 11a9.17 9.17 0 0 0-.07 1.19 9.28 9.28 0 0 0 .07 1.19l-2.57 2a.64.64 0 0 0-.15.78l2.43 4.2a.62.62 0 0 0 .75.27l3-1.22a8 8 0 0 0 2.07 1.19l.46 3.21a.6.6 0 0 0 .6.51h4.86a.63.63 0 0 0 .61-.51l.46-3.21a8.85 8.85 0 0 0 2-1.19l3 1.22a.64.64 0 0 0 .75-.27l2.43-4.2a.63.63 0 0 0-.07-.82zm-11.6.44a3.64 3.64 0 1 1 3.64-3.64 3.64 3.64 0 0 1-3.64 3.64zM43.86 23L42 21.55a8.47 8.47 0 0 0 .07-.88 4.94 4.94 0 0 0-.07-.87l1.89-1.48a.48.48 0 0 0 .11-.57l-1.79-3.09a.45.45 0 0 0-.55-.2l-2.21.9a6.41 6.41 0 0 0-1.52-.88l-.34-2.35a.42.42 0 0 0-.43-.38h-3.62a.45.45 0 0 0-.44.38l-.34 2.35a7 7 0 0 0-1.52.88l-2.24-.9a.46.46 0 0 0-.55.2l-1.79 3.09a.45.45 0 0 0 .11.57l1.89 1.48a8.14 8.14 0 0 0-.05.87 8.49 8.49 0 0 0 .05.88L26.8 23a.47.47 0 0 0-.11.57l1.79 3.08a.45.45 0 0 0 .55.2l2.21-.85a6.37 6.37 0 0 0 1.52.87l.34 2.36a.43.43 0 0 0 .44.37h3.57a.46.46 0 0 0 .45-.37l.34-2.36a7 7 0 0 0 1.5-.87l2.23.89a.47.47 0 0 0 .55-.2L44 23.6a.46.46 0 0 0-.14-.6zm-8.53.32A2.68 2.68 0 1 1 38 20.67a2.68 2.68 0 0 1-2.67 2.68zM24.62 35.14l-1.24-1a3.55 3.55 0 0 0 .05-.57 2.69 2.69 0 0 0-.05-.57l1.23-1a.29.29 0 0 0 .07-.37l-1.16-2a.3.3 0 0 0-.36-.12l-1.44.58a3.7 3.7 0 0 0-1-.57l-.22-1.53a.28.28 0 0 0-.28-.25h-2.3a.3.3 0 0 0-.29.25l-.22 1.53a4.18 4.18 0 0 0-1 .57L15 29.58a.3.3 0 0 0-.36.12l-1.16 2a.28.28 0 0 0 .07.37l1.23 1a4.08 4.08 0 0 0 0 1.14l-1.23 1a.29.29 0 0 0-.07.37l1.16 2a.3.3 0 0 0 .36.13l1.44-.58a4 4 0 0 0 1 .57l.22 1.53a.29.29 0 0 0 .29.24h2.32a.3.3 0 0 0 .29-.24l.22-1.53a4.67 4.67 0 0 0 1-.57l1.45.58a.31.31 0 0 0 .36-.13l1.16-2a.29.29 0 0 0-.13-.44zm-5.54.21a1.74 1.74 0 1 1 1.74-1.74 1.74 1.74 0 0 1-1.74 1.74z" />
      </g>
    </g>
  </svg>
)

export default Psychometric

import React from 'react'
import { Row, Col } from 'reactstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import articleUrl from 'src/utils/articleUrl'
import dayjs from 'dayjs'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const RecentlyUpdatedArticles = () => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsArticle(limit: 4) {
          edges {
            node {
              title
              id
              slug
              shortUrl
              category {
                id
                category {
                  title
                  id
                  slug
                }
                title
                slug
              }
              coverImage {
                url(
                  imgixParams: {
                    auto: "[compress, format, enhance]"
                    fit: "fill"
                    w: "400"
                    h: "320"
                  }
                )
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="further-reading text-center">
        <h2 className="heading-orange">Recently Updated Articles</h2>
        <p>Some of our fresh articles that we bring to your attention.</p>

        <Row className="pt-4">{data.allDatoCmsArticle.edges.map(articleCard)}</Row>
      </div>
    )}
  />
)

function articleCard({ node: article }) {
  return (
    <Col lg={3} md={4} sm={6} xs={12} className="col" key={article.id}>
      <Link className="article-link bg-light" to={articleUrl(article)}>
        <div
          className="article-cover-image"
          style={{ backgroundImage: `url(${itemImage(article)})` }}
        />
        <span className="article-title">{formatYear(article.title)}</span>
      </Link>
    </Col>
  )
}

function itemImage(article) {
  return article.coverImage ? article.coverImage.url : fallbackImage
}

export default RecentlyUpdatedArticles

import React from 'react'

const JobApplications = props => (
  <svg width={35.47} height={39.41} {...props}>
    <g data-name="Layer 2">
      <path
        fill="#fd8023"
        d="M31.53 3.94h-8.24a5.89 5.89 0 0 0-11.11 0H3.94A4 4 0 0 0 0 7.88v27.59a4 4 0 0 0 3.94 3.94h27.59a4 4 0 0 0 3.94-3.94V7.88a4 4 0 0 0-3.94-3.94zm-13.8 0a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm3.95 27.59H7.88v-3.94h13.8zm5.91-7.88H7.88V19.7h19.71zm0-7.89H7.88v-3.94h19.71z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default JobApplications

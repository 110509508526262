import React from 'react'
import { Link } from 'gatsby'
import styles from './Splash.module.scss'
import { Container, Row, Col } from 'reactstrap'
import splashBackground from 'src/images/splash-bg.jpg'

const SplashCTASection = () => {
  return (
    <div
      className={styles.splashSection}
      style={{
        backgroundImage: `url(${splashBackground})`,
      }}
    >
      <Container>
        <Row>
          <Col md={6} sm={12} xs={12}>
            <h2>Get The Job You Want</h2>
            <p>
              Learn how to succeed at interviews, assessment centres and psychometric tests.
            </p>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Row>
              <Col lg={6} md={12} sm={6} xs={12}>
                <Link to="/content/interview-advice">
                  <span className={styles.orangeButton}>
                    Read About Interviews
                  </span>
                </Link>
              </Col>
              <Col lg={6} md={12} sm={6} xs={12}>
                <Link to="/aptitude-tests-home">
                  <span className={styles.whiteButton}>
                    Practice Free Aptitude Tests
                  </span>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SplashCTASection

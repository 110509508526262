import React from 'react'

const LogoDeloitte = props => (
  <svg width={110.32} height={20.63} {...props}>
    <g data-name="Layer 2">
      <g fill="#283141" opacity={0.4} data-name="Layer 1">
        <path d="M104.47 17.7a2.93 2.93 0 1 1 2.93 2.93 2.93 2.93 0 0 1-2.93-2.93M11.22 10a6.57 6.57 0 0 0-1.07-4.12A3.91 3.91 0 0 0 6.89 4.5H5.33v11.32h1.19a4.28 4.28 0 0 0 3.57-1.44A7.17 7.17 0 0 0 11.22 10m5.54-.19q0 5.06-2.72 7.8T6.39 20.3H0V.09h6.83q4.76 0 7.34 2.48t2.59 7.2M34.45 20.3h5.08V0h-5.08zM46.67 12.73a6.78 6.78 0 0 0 .51 3 1.75 1.75 0 0 0 1.7 1 1.7 1.7 0 0 0 1.67-1 7 7 0 0 0 .49-3 6.66 6.66 0 0 0-.49-2.91 1.93 1.93 0 0 0-3.37 0 6.5 6.5 0 0 0-.51 2.92m9.54 0a8.07 8.07 0 0 1-1.94 5.77 7.11 7.11 0 0 1-5.44 2.07 7 7 0 0 1-5.34-2.12 8 8 0 0 1-2-5.72 8 8 0 0 1 2-5.73 7.16 7.16 0 0 1 5.45-2 7.63 7.63 0 0 1 3.83.94 6.41 6.41 0 0 1 2.57 2.71 9 9 0 0 1 .9 4.12M58.23 20.3h5.08V5.22h-5.08zM58.23 3.4h5.08V0h-5.08zM73.61 16.46a7.54 7.54 0 0 0 2.46-.52v3.79a10.21 10.21 0 0 1-2 .65 10.68 10.68 0 0 1-2.17.19 4.7 4.7 0 0 1-3.7-1.29 5.85 5.85 0 0 1-1.13-3.94V9.12h-1.74v-3.9h1.78V1.37l5.13-.89v4.74h3.24v3.9h-3.24V15c0 1 .45 1.47 1.37 1.47M85.38 16.46a7.54 7.54 0 0 0 2.45-.52v3.79a9.91 9.91 0 0 1-2 .65 10.78 10.78 0 0 1-2.18.19A4.67 4.67 0 0 1 80 19.28a5.79 5.79 0 0 1-1.13-3.94V9.12H77.1v-3.9h1.78V1.31L84 .48v4.74h3.24v3.9H84V15q0 1.47 1.38 1.47M94.25 10.89A2.75 2.75 0 0 1 95 9.08a2.1 2.1 0 0 1 1.51-.58 1.94 1.94 0 0 1 1.49.65 2.56 2.56 0 0 1 .6 1.74zm7.16-4.16A8.23 8.23 0 0 0 90.89 7 8.37 8.37 0 0 0 89 12.86a7.69 7.69 0 0 0 2 5.71 7.83 7.83 0 0 0 5.72 2 16.72 16.72 0 0 0 3-.24 8.63 8.63 0 0 0 2.45-.86l-.78-3.4a10.44 10.44 0 0 1-1.64.54 11.12 11.12 0 0 1-2.46.26 3.46 3.46 0 0 1-2.32-.72 2.62 2.62 0 0 1-.91-2h9.09v-2.3a6.85 6.85 0 0 0-1.82-5.12M23.5 10.89a2.75 2.75 0 0 1 .71-1.81 2.09 2.09 0 0 1 1.5-.58 2 2 0 0 1 1.56.65 2.67 2.67 0 0 1 .6 1.74zm7.17-4.16A7.05 7.05 0 0 0 25.53 5a7 7 0 0 0-5.38 2 8.37 8.37 0 0 0-1.88 5.86 7.65 7.65 0 0 0 2 5.71 7.82 7.82 0 0 0 5.71 2 16.57 16.57 0 0 0 3-.24 8.58 8.58 0 0 0 2.46-.86l-.78-3.4a10.79 10.79 0 0 1-1.64.54 11.15 11.15 0 0 1-2.47.26 3.48 3.48 0 0 1-2.32-.72 2.61 2.61 0 0 1-.9-2h9.09v-2.3a6.85 6.85 0 0 0-1.82-5.12" />
      </g>
    </g>
  </svg>
)

export default LogoDeloitte

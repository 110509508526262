import React from 'react'

const LogoKpmg = props => (
  <svg width={63.78} height={24.86} {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          fill="#283141"
          opacity={0.4}
          d="M57.27 24.14l-.22.09a20.67 20.67 0 0 1-5.21.63A6.16 6.16 0 0 1 47 22.63a5 5 0 0 1-.93-3h-1.64l-1.22 4.9h-4.14l1.46-4.9h-1.32l-3.16 4.9h-3.33l-.29-4.9H31l-1.45 4.9H26l1.5-4.9h-8.37v-.07l-1.49 5h-3.78l1.48-4.91h-4.61L13 24.53H8.66l-2-4.91H5.5C4.76 22 4.18 24 4.13 24.13l-.07.4H0l3.71-12.36V0h13.93v12l.53-1.77h1V0H33v10.25h1.49V0H48.4v13a7.63 7.63 0 0 1 1.49-1.55V0h13.89v19.62h-5.25z"
        />
        <path
          fill="#fff"
          d="M24.78 14.75c-.45 1.35-1.06 2.05-3.06 2.09h-2.37l1.22-4.18c2.99-.01 4.99-.25 4.21 2.09zm-17.12-4h-3L.67 24h3c.04-.25 3.99-13.25 3.99-13.25zm7.81 0h-3.65L6 16.61 9 24h3.25L8.9 16.89zm12.06 4c.75-4.09-2.55-4-5.61-4h-3.38L14.53 24h2.73l1.57-5.25H21c4.26.03 6.13-1.85 6.53-4zm18.59-4h-4.36c-5.3 7.35-6.58 11.48-6.58 11.48A69.94 69.94 0 0 0 35 10.75h-4.29L26.64 24h2.5l3.37-11.37.68 11.37h2.59l7.33-11.37L39.74 24h3.07zm8.65-.29c-2.67 0-6 .6-7.89 6.87-1.6 5.24 2.28 7 5 7a20.63 20.63 0 0 0 5-.59l1.88-6.71h-5.65l-.62 2h3L54.53 22s.12.45-2.11.46c-2.82 0-3.6-2-2.67-5.18.85-3 2.23-5 4.75-4.88 1.73.08 2.69.72 2.22 2.31a2.45 2.45 0 0 1-.09.32h2.73c.7-2-.69-4.56-4.59-4.56"
        />
      </g>
    </g>
  </svg>
)

export default LogoKpmg

import React from 'react'
import { Link } from 'gatsby'

export default function InterviewCard(props) {
  return (
    <div className="pt-4">
      <Link to={props.url}>
        <div className="card text-white">
          <img className="card-img" src={props.image} alt={props.title} />
          <div
            className="card-img-overlay"
            style={{ background: `rgba(0,0,0,0.7)` }}
          >
            <h2
              className="card-title"
              style={{
                fontSize: '1.3em',
                fontFamily: 'PT Sans, sans-serif',
                paddingTop: 0,
                marginTop: '-4px',
                lineHeight: '1em',
                height: '60px',
              }}
            >
              {props.title}
            </h2>
            <p className="card-text" style={{ fontWeight: 700, lineHeight: '1em' }}>
              {props.description}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

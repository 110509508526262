import React from 'react'

const Interview = props => (
  <svg width={45.85} height={36.74} {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1" fill="#fd8023">
        <path d="M44.16 0h-13.5A1.68 1.68 0 0 0 29 1.69v15.18l3.37-3.37h11.79a1.69 1.69 0 0 0 1.69-1.69V1.69A1.69 1.69 0 0 0 44.16 0zM32.35 5.91h10.13v1.68H32.35zm6.75 4.21h-6.75V8.44h6.75zm3.38-5.06H32.35V3.37h10.13z" />
        <circle cx={17.29} cy={12.92} r={7.52} />
        <path d="M17.29 23.76C11.52 23.76 0 26.66 0 32.41v4.33h34.59v-4.33c0-5.75-11.52-8.65-17.3-8.65z" />
      </g>
    </g>
  </svg>
)

export default Interview

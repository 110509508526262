import React from 'react'

const Diagrammatic = props => (
  <svg width={37.22} height={35.26} {...props}>
    <g data-name="Layer 2">
      <path
        fill="#fd8023"
        d="M12.73 27.43H8.82V13.71h3.91zm7.84 0h-3.92V7.84h3.92zm7.83 0h-3.91v-7.84h3.91zm4.9 4.11H3.92V3.92H33.3zM33.3 0H3.92A3.93 3.93 0 0 0 0 3.92v27.42a3.93 3.93 0 0 0 3.92 3.92H33.3a3.93 3.93 0 0 0 3.92-3.92V3.92A3.93 3.93 0 0 0 33.3 0z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default Diagrammatic

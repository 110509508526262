import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'
import { StaticQuery, graphql } from 'gatsby'
import articleUrl from 'src/utils/articleUrl'
import dayjs from 'dayjs'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const TrendingArticles = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsTrending {
          trendingItem {
            trendingArticles {
              title
              id
              slug
              shortUrl
              category {
                id
                category {
                  title
                  id
                  slug
                }
                title
                slug
              }
              coverImage {
                fluid(
                  imgixParams: {
                    auto: "compress, format, enhance"
                    fit: "fill"
                    w: "400"
                    h: "320"
                  }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="further-reading text-center">
        <h2>Trending Articles</h2>

        <Row className="pt-4">
          {data.datoCmsTrending.trendingItem[0].trendingArticles
            .map(articleCard)
            .filter((a, i) => i < 4)}
        </Row>
      </div>
    )}
  />
)

const itemImage = (image) => (
  image ? (
    <Img fluid={image.fluid} className="article-cover-image" />
  ) : (
    <div
      className="article-cover-image"
      style={{ backgroundImage: `url(${fallbackImage})` }}
    />
  )
)

function articleCard(article) {
  return (
    <Col lg={3} md={4} sm={6} xs={12} className="col" key={article.id}>
      <Link className="article-link" to={articleUrl(article)}>
        {itemImage(article.coverImage)}
        <span className="article-title">{formatYear(article.title)}</span>
      </Link>
    </Col>
  )
}

export default TrendingArticles
